<template>
  <div>
    <my-header></my-header>
    <div class="table-box">
      <div>
        <span>班组详情</span>
        <span @click="goBack" class="back">返回所有班组</span>
      </div>
      <div class="detail">
        <div>
          <span>班组名称:</span>
          <span
            v-text="
              detail.groupName != '' && detail.groupName != null
                ? detail.groupName
                : '没有名字'
            "
          ></span>
        </div>
        <div>
          <span>班组长:</span>
          <span
            v-text="
              detail.realName != '' && detail.realName != null
                ? detail.realName
                : '没有留下姓名'
            "
          ></span>
        </div>
        <div>
          <span>班组长电话:</span>
          <span
            v-text="
              detail.phone != '' &&
              detail.phone != null
                ? detail.phone
                : '没有留下电话'
            "
          ></span>
        </div>
        <div>
          <span>班组类型:</span>
          <span
            v-text="
              detail.groupType != '' &&
              detail.groupType != null
                ? detail.groupType
                : '没有类型'
            "
          ></span>
        </div>
        <div>
          <span>班组人数:</span>
          <span
            v-text="
              detail.groupNum != '' &&
              detail.groupNum!= null
                ? detail.groupNum
                : '没有人'
            "
          ></span>
        </div>
        <div>
          <span>班组组建时间:</span>
          <span
            v-text="
              detail.createTime != '' &&
              detail.createTime!= null
                ? detail.createTime
                : '无'
            "
          ></span>
        </div>
        <div>
          <span>地址:</span>
          <span
            v-if="detail.areaName"
            v-text="`${detail.areaName[0]}${detail.areaName[1]}${detail.areaName[2]}`"
          ></span>
          <span v-else> 没有留下地址信息 </span>
        </div>
       <div>
          <span>简介:</span>
          <span
            v-text="
              detail.groupNotice != '' &&
              detail.groupNotice != null
                ? detail.groupNotice
                : '没有简介'
            "
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyHeader from "../components/my-header.vue";

export default {
  data() {
    return {
      detail:{},
    };
  },
  methods: {
    getItem() {
      this.detail = JSON.parse(this.$route.query.item);
      console.log(this.detail,'详情');
    },
    goBack(){
      this.$router.back()
  },
  },
  
  created() {
    this.getItem();
  },
  
};
</script>

<style lang="scss" scoped>
.table-box {
  margin-top: 140px;
  & > div:nth-child(1) {
    background: #f5f5f6;
    height: 150px;
    margin: 0 auto;
    font-size: 36px;
    color: #101d37;
    font-weight: 700;
    text-align: center;
    line-height: 120px;
    position: relative;
    color: #101d37 !important;
    &>.back {
      position: absolute;
      right: 150px;
      top: 50px;
      height: 30px;
      color: #5079d9;
      line-height: 0px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .detail {
    width: 100%;
    color: #666;
    border: 1px solid #ccc;
    div {
      padding: 5px;
    }
    div:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
    div > span:nth-child(1) {
      display: inline-block;
      text-align: right;
      width: 50%;
    }
    div > span:nth-child(2) {
      margin-left: 15px;
    }
    .file {
      text-align: center;
      .downLoadUrl {
        color: #409eff;
        cursor: pointer;
      }
    }
  }
}
</style>
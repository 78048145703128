var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('my-header'),_c('div',{staticClass:"table-box"},[_c('div',[_c('span',[_vm._v("班组详情")]),_c('span',{staticClass:"back",on:{"click":_vm.goBack}},[_vm._v("返回所有班组")])]),_c('div',{staticClass:"detail"},[_c('div',[_c('span',[_vm._v("班组名称:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.groupName != '' && _vm.detail.groupName != null
              ? _vm.detail.groupName
              : '没有名字'
          )}})]),_c('div',[_c('span',[_vm._v("班组长:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.realName != '' && _vm.detail.realName != null
              ? _vm.detail.realName
              : '没有留下姓名'
          )}})]),_c('div',[_c('span',[_vm._v("班组长电话:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.phone != '' &&
            _vm.detail.phone != null
              ? _vm.detail.phone
              : '没有留下电话'
          )}})]),_c('div',[_c('span',[_vm._v("班组类型:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.groupType != '' &&
            _vm.detail.groupType != null
              ? _vm.detail.groupType
              : '没有类型'
          )}})]),_c('div',[_c('span',[_vm._v("班组人数:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.groupNum != '' &&
            _vm.detail.groupNum!= null
              ? _vm.detail.groupNum
              : '没有人'
          )}})]),_c('div',[_c('span',[_vm._v("班组组建时间:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.createTime != '' &&
            _vm.detail.createTime!= null
              ? _vm.detail.createTime
              : '无'
          )}})]),_c('div',[_c('span',[_vm._v("地址:")]),(_vm.detail.areaName)?_c('span',{domProps:{"textContent":_vm._s(("" + (_vm.detail.areaName[0]) + (_vm.detail.areaName[1]) + (_vm.detail.areaName[2])))}}):_c('span',[_vm._v(" 没有留下地址信息 ")])]),_c('div',[_c('span',[_vm._v("简介:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.detail.groupNotice != '' &&
            _vm.detail.groupNotice != null
              ? _vm.detail.groupNotice
              : '没有简介'
          )}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }